import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  acknowledgedBatches: {},
  laterClickedTimestamps: {},
};

const practiceSheetSlice = createSlice({
  name: 'practiceSheetNudge',
  initialState,
  reducers: {
    setAcknowledgedStatus: (state, action) => {
      const { batchId, acknowledged } = action.payload;
      if (batchId) {
        state.acknowledgedBatches[batchId] = acknowledged;
      }
    },
    setLaterClickedTimestamp: (state, action) => {
      const { batchId } = action.payload;
      if (batchId) {
        state.laterClickedTimestamps[batchId] = Date.now();
      }
    },
    clearLaterClickedTimestamp: (state, action) => {
      const { batchId } = action.payload;
      if (batchId) {
        delete state.laterClickedTimestamps[batchId];
      }
    },
    resetPracticeSheetNudge: () => initialState,
  }
});

const getStateSlice = (state) => state.practiceSheetNudge || initialState;

export const getAcknowledgedStatusForBatch = (state, batchId) => 
  batchId ? (getStateSlice(state).acknowledgedBatches || {})[batchId] : false;

export const getLaterClickedTimestampForBatch = (state, batchId) => 
  batchId ? (getStateSlice(state).laterClickedTimestamps || {})[batchId] : undefined;

export const { 
  setAcknowledgedStatus, 
  setLaterClickedTimestamp, 
  clearLaterClickedTimestamp,
  resetPracticeSheetNudge, 
} = practiceSheetSlice.actions;

export default practiceSheetSlice.reducer;